<template>
  <section class="contact" id="contact">
    <h2>Contactez-nous</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="name">Nom :</label>
        <input type="text" id="name" v-model="name" @input="validateName">
        <span v-if="errors.name" class="error">{{ errors.name }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email :</label>
        <input type="email" id="email" v-model="email" @input="validateEmail">
        <span v-if="errors.email" class="error">{{ errors.email }}</span>
      </div>
      <div class="form-group">
        <label for="message">Message :</label>
        <textarea id="message" v-model="message" @input="validateMessage"></textarea>
        <span v-if="errors.message" class="error">{{ errors.message }}</span>
      </div>
      <button type="submit" class="btn">Envoyer</button>
    </form>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      email: '',
      message: '',
      errors: {}
    };
  },
  methods: {
    validateName() {
      this.errors.name = this.name.length < 3 ? "Le nom doit contenir au moins 3 caractères." : '';
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors.email = !emailPattern.test(this.email) ? "Veuillez entrer une adresse email valide." : '';
    },
    validateMessage() {
      this.errors.message = this.message.length < 10 ? "Le message doit contenir au moins 10 caractères." : '';
    },
    validateForm() {
      this.validateName();
      this.validateEmail();
      this.validateMessage();

      return !this.errors.name && !this.errors.email && !this.errors.message;
    },
    async submitForm() {
      if (this.validateForm()) {
        try {
          const response = await axios.post('/contact', {
            name: this.name,
            email: this.email,
            message: this.message,
          });
          if (response.data.success) {
            alert("Votre message a été envoyé avec succès !");
            this.name = '';
            this.email = '';
            this.message = '';
            this.errors = {};
          } else {
            alert("Une erreur est survenue. Veuillez réessayer.");
          }
        } catch (error) {
          console.error("Erreur lors de l'envoi du formulaire :", error);
          alert("Une erreur est survenue. Veuillez réessayer.");
        }
      }
    }
  }
};
</script>

<style scoped>
section.contact {
  padding: 60px 0;
  text-align: center;
}

.contact h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.contact form {
  max-width: 600px;
  margin: 0 auto;
}

.contact .form-group {
  margin-bottom: 20px;
  text-align: left;
}

.contact .form-group label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact .form-group input,
.contact .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact .form-group textarea {
  resize: none;
  height: 150px;
}

.contact .btn {
  display: block;
  width: 100%;
  background-color: #f39c12;
  color: white;
  padding: 15px 0;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact .btn:hover {
  background-color: #d35400;
}

.error {
  color: red;
  font-size: 0.9em;
}
</style>
