<template>
  <div id="app">
    <SiteNavBar />
    <main>
      <HeroSection />
      <VisionSection />
      <ServicesSection />
      <ChooseUsSection />
      <ContactSection />
    </main>
    <SiteFooter />
  </div>
</template>

<script>
import SiteNavBar from '@/components/SiteNavBar.vue';
import HeroSection from '@/components/HeroSection.vue';
import VisionSection from '@/components/VisionSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import ChooseUsSection from '@/components/ChooseUsSection.vue';
import ContactSection from '@/components/ContactSection.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    SiteNavBar,
    HeroSection,
    VisionSection,
    ServicesSection,
    ChooseUsSection,
    ContactSection,
    SiteFooter,
  },
};
</script>

<style>
:root {
  --background-color: #ffffff;
  --text-color: #333333;
  --primary-color: #f39c12;
  --secondary-color: #d35400;
  --accent-color: #3498db;
  --light-background-color: #f5f5f5;
}

[data-theme="dark"] {
  --background-color: #1e1e1e;
  --text-color: #f5f5f5;
  --primary-color: #f39c12;
  --secondary-color: #d35400;
  --accent-color: #3498db;
  --light-background-color: #2c2c2c;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

section {
  padding: 40px 20px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
}
</style>
