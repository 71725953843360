<template>
  <div class="simulateur-form">
    <h1>Simulateur d'Aides MaPrimeRénov'</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="type_logement">Type de Logement :</label>
        <select v-model="typeLogement" id="type_logement" class="form-control" required>
          <option value="">Sélectionner...</option>
          <option value="individuel">Individuel</option>
          <option value="copropriete">Copropriété</option>
        </select>
      </div>

      <div v-if="typeLogement === 'individuel'">
        <div class="form-group">
          <label for="region">Localisation Géographique :</label>
          <select v-model="region" id="region" class="form-control" required>
            <option value="">Sélectionner...</option>
            <option value="idf">Île-de-France</option>
            <option value="hors_idf">Hors Île-de-France</option>
          </select>
        </div>

        <div class="form-group">
          <label for="nombre_personnes">Nombre de personnes dans le foyer :</label>
          <input v-model="nombrePersonnes" type="number" id="nombre_personnes" class="form-control" required>
        </div>

        <div class="form-group">
          <label for="revenu_annuel">Revenu Annuel (€) :</label>
          <input v-model="revenuAnnuel" type="number" id="revenu_annuel" class="form-control" required>
        </div>

        <div class="form-group">
          <label for="categorie">Catégorie de Revenu :</label>
          <input :value="categorie" type="text" id="categorie" class="form-control" readonly>
        </div>
      </div>

      <button type="submit" class="btn btn-success mt-3">Continuer</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      typeLogement: '',
      region: '',
      nombrePersonnes: '',
      revenuAnnuel: '',
      categorie: ''
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('http://localhost:5000/get_categorie', {
          region: this.region,
          revenu: this.revenuAnnuel,
          nombre_personnes: this.nombrePersonnes
        });
        this.categorie = response.data.categorie;
        // Redirigez vers la page de choix des travaux après la détermination de la catégorie
        this.$router.push({ name: 'ChoixTravaux', params: { categorie: this.categorie } });
      } catch (error) {
        console.error('Erreur lors de la soumission du formulaire:', error);
      }
    }
  }
}
</script>

<style scoped>
.simulateur-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}
</style>
