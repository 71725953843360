<template>
  <section class="services" id="services">
    <h2>Découvrez Nos Services Innovants pour des Économies Durables</h2>
    <div class="service-cards">
      <div class="card card-audit">
        <h3>Audit Énergétique Personnalisé</h3>
        <p>Identifiez chaque opportunité d'économie d'énergie dans votre habitat avec un audit complet et détaillé.</p>
      </div>
      <div class="card card-renovation">
        <h3>Conseil Stratégique en Rénovation</h3>
        <p>Maximisez l'efficacité de vos projets de rénovation grâce à notre expertise en matériaux et technologies durables.</p>
      </div>
      <div class="card card-simulation">
        <h3>Simulations Thermiques Avancées</h3>
        <p>Visualisez l'impact de vos décisions avant même d'engager les travaux grâce à nos outils de simulation de pointe.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section.services {
  padding: 60px 20px;
  text-align: center;
  background-color: var(--background-color);
}

.services h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: var(--text-color);
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Ajuste la largeur minimale des boxes */
  gap: 20px;
}

.service-cards .card {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-size 0.3s ease;
  color: #ffffff; /* Texte blanc */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Améliore la visibilité du texte */
  overflow: hidden;
  min-height: 300px; /* Ajuste ici la hauteur des boxes */
}

.service-cards .card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Transparence initiale */
  transition: background-color 0.3s ease;
  z-index: 1;
}

.service-cards .card:hover::before {
  background-color: rgba(0, 0, 0, 0.4); /* Voile gris au survol */
}

.service-cards .card h3, 
.service-cards .card p {
  position: relative;
  z-index: 2; /* Assure que le texte est au-dessus du voile */
}

.service-cards .card:hover {
  transform: translateY(-10px);
  background-size: 110%;
}

.service-cards .card h3 {
  font-size: 2.5em;
  margin-bottom: 15px;
  font-weight: bold; /* Met le titre en gras */
}

.service-cards .card p {
  font-size: 1.2em;
}

.card-audit {
  background-image: url('@/assets/energy_auditor.png');
}

.card-renovation {
  background-image: url('@/assets/before-after.png');
}

.card-simulation {
  background-image: url('@/assets/vmc.png');
}
</style>
