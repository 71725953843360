import { createRouter, createWebHistory } from 'vue-router';
import SimulateurForm from '@/components/SimulateurForm.vue';
import ChoixTravaux from '@/components/ChoixTravaux.vue';

const routes = [
  {
    path: '/',
    name: 'SimulateurForm',
    component: SimulateurForm,
  },
  {
    path: '/choix-travaux',
    name: 'ChoixTravaux',
    component: ChoixTravaux,
    props: true,  // Pour passer des props comme `categorie`
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
