<template>
  <section class="hero" id="hero">
    <div class="hero-content">
      <h1>Améliorez l'efficacité énergétique de votre habitat</h1>
      <p>
        Profitez de notre expertise pour réduire vos factures d'énergie et améliorer votre confort au quotidien. Bénéficiez d'une consultation gratuite dès aujourd'hui.
      </p>
      <a href="#services" class="btn" @click="scrollToSection('services')">Découvrez nos services</a>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
/* Styles pour la section Hero */
.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/hero.png') no-repeat center center/cover;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
}

.hero-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.hero-content .btn {
  background-color: #f39c12;
  color: white;
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 2;
  position: relative;
}

.hero-content .btn:hover {
  background-color: #d35400;
}
</style>
