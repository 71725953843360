<template>
  <footer>
    <p>&copy; 2024 Diagnostic Energ'Ethique. Tous droits réservés.</p>
  </footer>
</template>

<style scoped>
footer {
  text-align: center;
  padding: 20px 0;
  background-color: var(--background-color);
  color: var(--text-color);
}
</style>
