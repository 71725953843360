<template>
  <header class="navbar">
    <div class="container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <nav>
        <ul class="nav-links">
          <li><a href="#" @click.prevent="scrollToSection('hero')">Accueil</a></li>
          <li><a href="#" @click.prevent="scrollToSection('vision')">Notre Vision</a></li>
          <li><a href="#" @click.prevent="scrollToSection('services')">Nos Services</a></li>
          <li><a href="#" @click.prevent="scrollToSection('choose-us')">Pourquoi Nous Choisir</a></li>
          <li><a href="#" @click.prevent="scrollToSection('contact')">Contact</a></li>
          
        </ul>
        <button @click="toggleTheme" class="theme-toggle-btn">
          {{ isDarkMode ? '☀️' : '🌙' }}
        </button>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
    };
  },
  mounted() {
    this.isDarkMode = localStorage.getItem('theme') === 'dark';
    this.applyTheme();
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      const offset = 80; // Ajustement pour éviter que le titre ne soit masqué par le menu
      const sectionPosition = section.offsetTop - offset;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    },
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light');
      this.applyTheme();
    },
    applyTheme() {
      document.documentElement.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
    },
  },
};
</script>

<style scoped>
header.navbar {
  background-color: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.logo img {
  max-height: 50px;
  width: auto;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  margin-right: 20px; /* Pour un petit décalage vers la gauche */
}

.nav-links a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.1em;
}

.theme-toggle-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 30px; /* Ajustement de la position pour être aligné avec le menu */
}
</style>
