<template>
  <section class="vision" id="vision">
    <h2>Notre Vision</h2>
    <p>
      Chez Diagnostic Energ'Ethique, nous vous accompagnons vers une gestion énergétique responsable, alliant économies et respect de l'environnement.
    </p>

    <div class="vision-container">
      <div class="progress-circles" ref="progressCircles">
        <div class="circle-container">
          <div class="circle-info">
            <h3>35%</h3>
            <p>Économie d'énergie</p>
          </div>
          <div ref="circle1" class="circle-chart"></div>
        </div>
        <div class="circle-container">
          <div class="circle-info">
            <h3>70%</h3>
            <p>Efficacité améliorée</p>
          </div>
          <div ref="circle2" class="circle-chart"></div>
        </div>
        <div class="circle-container">
          <div class="circle-info">
            <h3>100%</h3>
            <p>Satisfaction client</p>
          </div>
          <div ref="circle3" class="circle-chart"></div>
        </div>
      </div>

      <div class="chart-container">
        <canvas id="myChart"></canvas>
        <div class="chart-info">
          <h3>1 200 tonnes</h3>
          <p>Réductions projetées de CO2</p>
          <h3 class="blue-text">+150 projets</h3>
          <p>Réalisés en 2024</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProgressBar from 'progressbar.js';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  mounted() {
    this.initIntersectionObserver();
    this.initChart();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.initProgressBars();
              observer.unobserve(entry.target); // Arrête d'observer une fois les cercles animés
            }
          });
        },
        { threshold: 0.5 } // L'animation démarre quand 50% de l'élément est visible
      );

      observer.observe(this.$refs.progressCircles);
    },
    initProgressBars() {
      const options = {
        strokeWidth: 6,
        trailWidth: 1,
        duration: 1400,
        text: {
          autoStyleContainer: false
        },
        from: { color: '#84d425', width: 1 },
        to: { color: '#84d425', width: 6 },
        step: function(state, circle) {
          circle.path.setAttribute('stroke', state.color);
          circle.path.setAttribute('stroke-width', state.width);

          const value = Math.round(circle.value() * 100);
          circle.setText(value + '%');
        }
      };

      this.circle1 = new ProgressBar.Circle(this.$refs.circle1, options);
      this.circle1.animate(0.35); // 35% Économie d'énergie

      options.from.color = '#0462c1';
      options.to.color = '#0462c1';
      this.circle2 = new ProgressBar.Circle(this.$refs.circle2, options);
      this.circle2.animate(0.70); // 70% Efficacité améliorée

      options.from.color = '#84d425';
      options.to.color = '#84d425';
      this.circle3 = new ProgressBar.Circle(this.$refs.circle3, options);
      this.circle3.animate(1.0); // 100% Satisfaction client
    },
    initChart() {
      const ctx = document.getElementById('myChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['2021', '2022', '2023', '2024'],
          datasets: [{
            label: 'Réduction des Émissions de CO2 (%)',
            data: [15, 30, 50, 80],
            backgroundColor: ['#0462c1', '#84d425', '#0462c1', '#84d425'],
          }]
        },
        options: {
          animation: {
            duration: 2000,
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.vision {
  padding: 60px 20px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}

.vision h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.vision p {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  font-weight: bold;
}

.vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.progress-circles {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-info {
  text-align: center;
  margin-bottom: 10px;
  color: var(--text-color);
}

.chart-container {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
}

#myChart {
  width: 100%;
}

.chart-info {
  text-align: center;
  margin-top: 20px;
}

.chart-info h3 {
  margin: 10px 0;
  font-size: 1.5em;
  color: #84d425;
}

.chart-info h3.blue-text {
  color: #0462c1;
}

.chart-info p {
  margin: 0;
  font-size: 1em;
  text-align: center;
}
</style>
