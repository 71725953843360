<template>
    <div class="choix-travaux">
      <h1>Sélectionnez les Postes de Rénovation</h1>
      <form @submit.prevent="submitTravaux">
        <div v-for="(travail, index) in travauxSelectionnes" :key="index" class="form-group">
          <label>Poste de Rénovation {{ index + 1 }} :</label>
          <select v-model="travail.id" class="form-control">
            <option v-for="(option, id) in travauxOptions" :value="id" :key="id">
              {{ option }}
            </option>
          </select>
          <div v-if="isolationTravail(travail.id)" class="form-group mt-2">
            <label>Surface concernée (m²) :</label>
            <input v-model="travail.surface" type="number" class="form-control" />
          </div>
        </div>
        <button type="button" class="btn btn-secondary mt-3" @click="ajouterTravail">Ajouter un autre poste</button>
        <button type="submit" class="btn btn-primary mt-3">Simuler les aides disponibles</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        travauxSelectionnes: [{ id: '', surface: '' }],
        travauxOptions: {
          1: 'Isolation des combles',
          2: 'Isolation des murs',
          3: 'Pompe à chaleur air/eau',
          // Ajoutez ici les autres types de travaux
        }
      };
    },
    methods: {
      isolationTravail(id) {
        return id == 1 || id == 2;  // Liste des travaux nécessitant une saisie de surface
      },
      ajouterTravail() {
        this.travauxSelectionnes.push({ id: '', surface: '' });
      },
      async submitTravaux() {
        try {
          const response = await axios.post('http://localhost:5000/resultats_aides', {
            travaux: this.travauxSelectionnes.map(travail => travail.id),
            surface: this.travauxSelectionnes.map(travail => travail.surface),
            categorie: this.$route.params.categorie
          });
          // Redirection vers la page de résultats
          this.$router.push({ name: 'ResultatsAides', params: { resultats: response.data } });
        } catch (error) {
          console.error('Erreur lors de la soumission des travaux:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .choix-travaux {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
  }
  </style>
  