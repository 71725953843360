<template>
  <section class="choisir" id="choose-us">
    <h2>Pourquoi Diagnostic Energ'Ethique est un bon choix</h2>
    <p>Rejoignez tous nos chers clients qui ont déjà fait confiance à notre expertise certifiée. Chaque projet est unique, et nous nous engageons à vous fournir des solutions sur mesure qui garantissent des résultats concrets et mesurables. Votre satisfaction n'est pas seulement notre objectif - c'est notre garantie.</p>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section.choisir {
  padding: 60px 20px;
  background-color: var(--light-background-color);
  text-align: center;
}

.choisir h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: var(--text-color);
}

.choisir p {
  font-size: 1.2em;
  color: var(--text-color);
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}
</style>
